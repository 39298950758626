import { useAlertsStore } from '@/stores/alerts'
import { ApiError } from '~/composables/api/useApi'

export function useAlert() {
  const store = useAlertsStore()
  const { t, te } = useI18n()

  function alert(messages: string[], translate = true): void {
    if (!translate) {
      store.showAlert(messages)
      return
    }

    const translatedMessages: string[] = []
    let hasUntranslatableMessage = false
    messages.forEach((message) => {
      if (!te(message)) {
        if (hasUntranslatableMessage) {
          // continue
          return
        }
        window.console.warn(message)
        message = 'base.error.unexpectedError'
        hasUntranslatableMessage = true
      }
      translatedMessages.push(t(message))
    })
    store.showAlert(translatedMessages)
    // persist store values before we navigate to another page
    store.$persist()
  }

  function apiAlert(error: ApiError): void {
    console.log('apiAlert', error)
    const messages = Object.values(error.messages)
    let translate = true
    if (error.status === 400) {
      translate = false
    }
    alert(messages, translate)
  }

  function hideAlert(): void {
    store.setVisible(false)
  }

  return {
    alert,
    apiAlert,
    hideAlert,
  }
}
